import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsDto} from '../model/SettingsDto';
import {Observable} from 'rxjs';
import {UrlService} from './url.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
  ) {
  }

  getUserSettings(): Observable<SettingsDto> {
    return this.http.get<SettingsDto>(this.urlService.URL_USER_SETTINGS());
  }

  setUserSettings(settingsDto: SettingsDto): Observable<SettingsDto> {
    return this.http.put<SettingsDto>(this.urlService.URL_USER_SETTINGS(), settingsDto);
  }
}
