<div id="banner">
  <div class="logo" [ngClass]="isLoggedIn ? 'logged' : 'unlogged'" *ngIf="!isMobileAppRoute">
    <img src="assets/logo.png" alt="logo">
  </div>

  <div class="lang-container" *ngIf="!isLoggedIn && !isMobileAppRoute">
    <img src="assets/french.svg" [class.unselected]="getLang() !== langEnum.FR"
         alt="french" (click)="setLang(langEnum.FR)">
    <img src="assets/english.svg" [class.unselected]="getLang() !== langEnum.EN"
         alt="english" (click)="setLang(langEnum.EN)">
    <img src="assets/german.svg" [class.unselected]="getLang() !== langEnum.DE"
         alt="german" (click)="setLang(langEnum.DE)">
  </div>

  <div class="nav" [ngClass]="isLoggedIn ? 'logged' : 'unlogged'">
    <div *ngIf="isLoggedIn" class="link" [ngClass]="currentTab === 'dashboard' ? 'active' : ''" routerLink="/products">
      <span i18n="@@dashboard">_dashboard_</span>
    </div>
    <div *ngIf="isLoggedIn" class="link" [ngClass]="currentTab === '/stats' ? 'active' : ''" routerLink="/stats">
      <span i18n="@@licenses">_licenses_</span>
    </div>
    <div *ngIf="isLoggedIn" class="link logoutLink" (click)="logout()">
      <span i18n="@@sign_out">_sign_out_</span>
    </div>
  </div>
</div>

<div [hidden]="!loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="keepProgressBarPlace" [hidden]="loading"></div>
