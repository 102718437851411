import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/shared/service/auth.service';
import { LoaderService } from 'src/shared/service/loader.service';
import {LocaleService} from '../../service/locale.service';
import {LangEnum} from '../../enum/lang.enum';

@Component({
  selector: 'dash-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;
  currentTab = '';
  loading: boolean;
  langEnum = LangEnum;

  private componentDestroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private localeService: LocaleService,
  ) {
  }

  ngOnInit(): void {
    this.authService.getLoggedInSubject().asObservable().subscribe(res => {
      this.isLoggedIn = res;
    });

    this.loaderService.isLoading.subscribe((loading) => this.loading = loading);

    this.setCurrentTab();
    this.router.events
      .pipe(takeUntil(this.componentDestroy))
      .subscribe(() => this.setCurrentTab());
  }

  ngOnDestroy(): void {
    this.componentDestroy.next(true);
    this.componentDestroy.complete();
  }

  logout(): void {
    this.authService
      .logOut()
      .subscribe(() => this.router.navigateByUrl('auth/login'));
  }

  private setCurrentTab(): void {
    this.currentTab = this.router.url.match('product') ? 'dashboard' : this.router.url;
  }

  getLang(){
    return this.localeService.getLang();
  }

  setLang(lang: LangEnum){
    this.localeService.setLang(lang);
  }

  //#region accessors
  get isMobileAppRoute() {
    return this.router.url.includes('mobile-app')
  }

  //#endregion
}
