import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {LoaderService} from 'src/shared/service/loader.service';
import {SwUpdate} from '@angular/service-worker';
import {AuthService} from '../shared/service/auth.service';
import {UserService} from '../shared/service/user.service';
import {LocaleService} from '../shared/service/locale.service';
import {LangEnum} from '../shared/enum/lang.enum';

@Component({
  selector: 'dash-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  loading: boolean;

  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private swUpdate: SwUpdate,
    private authService: AuthService,
    private userService: UserService,
    private localeService: LocaleService,
  ) {
  }

  ngAfterViewInit(): void {
    this.swUpdate.versionUpdates.subscribe(() => window.location.reload());

    this.loaderService.isLoading.subscribe((loading) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });
    this.applyUserSettings();
  }

  /**
   * If user is already logged, his settings from db overwrite any other choice
   */
  applyUserSettings(){
    if (this.authService.isUserLoggedIn()){
      this.userService.getUserSettings().subscribe(
        settingsDto => {
          this.localeService.setLang(settingsDto.language as LangEnum);
        }
      );
    }
  }
}
