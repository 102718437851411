import {Injectable} from '@angular/core';
import {LangEnum} from '../enum/lang.enum';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  selectedLang: LangEnum;

  constructor() {
    this.selectedLang = LocaleService.getSiteLocale();
  }

  /**
   * Detect locale from url
   */
  private static getSiteLocale(): LangEnum {
    const localePath = window.location.pathname.split('/')[1];
    const lang = (localePath === 'en' || localePath === 'de') ? localePath : 'fr';
    return lang as LangEnum;
  }

  getLang(): LangEnum{
    return this.selectedLang;
  }

  /**
   * Change url according to user choice
   * @param lang Locale chosen from banner
   */
  setLang(lang: LangEnum): void {
    this.selectedLang = lang;
    if (LocaleService.getSiteLocale() !== this.selectedLang) {
      window.location.href = `/${this.selectedLang}/`;
    }
  }
}
