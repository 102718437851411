import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // Each time we redirect, the user interface has to verify if the user is logged in or not (to update the view in consequence)
    this.authService.getLoggedInSubject().next(this.authService.isUserLoggedIn());

    // The variable `canActivate` means here if the redirection is allowed or not.
    let canActivate: boolean = this.authService.isUserLoggedIn();
    const isUrl = paths => paths.some(path => path === route.routeConfig.path);

    if (!canActivate && !isUrl(['login', 'reset-password', 'change-password'])) {
      this.router.navigateByUrl('/auth/login');
    } else if (canActivate && isUrl(['login'])) {
      // We redirect the authentication pages to the products page if the token in the local storage is already valid.
      this.router.navigateByUrl('/products');
    } else {
      canActivate = true;
    }
    return canActivate;
  }
}
