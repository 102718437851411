import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UrlService } from './url.service';

@Injectable()
export class AuthService {

  static TOKEN_KEY = 'jwtToken';
  private static REFRESH_TOKEN_KEY = 'jwtRefreshToken';
  private static APPLICATION_KEY = 'VBI';

  private loggedIn = new Subject<boolean>();

  constructor(private http: HttpClient, private urlService: UrlService) { }

  refreshToken(): Observable<any> {
    return this.http.post<any>(this.urlService.URL_REFRESH_TOKEN(), { refreshToken: this.getRefreshToken() }, { observe: 'response' })
      .pipe(tap((res: any) => {
        this.store(res.body.jwtToken, AuthService.TOKEN_KEY);
      })
      );
  }

  logIn({ email, password }): Observable<any> {
    return this.http
      .post<any>(this.urlService.URL_LOGIN(), { email, password, application: AuthService.APPLICATION_KEY })
      .pipe(tap((res: any) => {
        this
          .store(res.jwtToken, AuthService.TOKEN_KEY)
          .store(res.jwtRefreshToken, AuthService.REFRESH_TOKEN_KEY);
      })
      );
  }

  logOut(): Observable<any> {
    return this.http
      .post<any>(this.urlService.URL_LOGOUT(), { refreshToken: this.getRefreshToken() })
      .pipe(tap((res: any) => {
        this.clear();
      })
      );
  }

  accountValidation(token: string): Observable<any> {
    return this.http.post<any>(this.urlService.URL_ACCOUNT_VALIDATION(), { token: token });
  }

  resetPassword({ email }): Observable<any> {
    return this.http.post<any>(this.urlService.URL_RESET_PASSWORD(), {
      email,
      application: AuthService.APPLICATION_KEY,
      redirectUrl: window.location.origin + '/auth/change-password'
    });
  }

  changePassword({ email, password = null, newPassword, token = null, application = AuthService.APPLICATION_KEY }): Observable<any> {
    return this.http.post<any>(this.urlService.URL_CHANGE_PASSWORD(), {
      email,
      newPassword,
      password,
      application: application,
      token
    });
  }

  store(token, key) {
    if (token !== localStorage.getItem(key)) {
      localStorage.setItem(key, token);
    }
    return this;
  }

  getToken(): string {
    return localStorage.getItem(AuthService.TOKEN_KEY);
  }

  hasRoleSocleDashboard(): boolean {
    return this.decodeToken().ROLES && (this.decodeToken().ROLES as string).includes('ROLE_VBI');
  }

  getRefreshToken(): string {
    return localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);
  }

  clear(): void {
    localStorage.removeItem(AuthService.TOKEN_KEY);
    localStorage.removeItem(AuthService.REFRESH_TOKEN_KEY);
  }

  isUserLoggedIn(): boolean {
    return null !== this.getToken();
  }

  getLoggedInSubject() {
    return this.loggedIn;
  }

  private decodeToken() {
    const base64Url = this.getToken().split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}
