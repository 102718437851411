import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  private url = (module, route): string => `/api/${module}/rest/v1/${route}`;

  // Auth
  URL_LOGIN = () => this.url('auth', 'login');
  URL_LOGOUT = () => this.url('auth', 'log-out');
  URL_REFRESH_TOKEN = () => this.url('auth', 'refresh');
  URL_ACCOUNT_VALIDATION = () => this.url('auth', 'validation');
  URL_RESET_PASSWORD = () => this.url('auth', 'reset-password');
  URL_CHANGE_PASSWORD = () => this.url('auth', 'change-password');

  // Dashboard
  URL_PRODUCTS = () => this.url('socle', 'dashboard/products');
  URL_PRODUCT_INFO = (serialNumber) => this.url('socle', `dashboard/product/${serialNumber}/info`);
  URL_PRODUCT_STATE = (serialNumber) => this.url('socle', `dashboard/product/${serialNumber}/state`);
  URL_UPDATE_PRODUCT_COMMENT = (serialNumber) => this.url('socle', `dashboard/product/${serialNumber}/comment`);
  URL_UNPAIR_PRODUCT = (serialNumber) => this.url('socle', `dashboard/product/${serialNumber}/unpair`);
  URL_ADD_PRODUCT_SUBSCRIPTION = (serialNumber) => this.url('socle', `dashboard/product/${serialNumber}/subscription`);
  URL_PRODUCTS_DETAILS = () => this.url('socle', `dashboard/products/details`);
  URL_EVENTS = (serialNumber) => this.url('socle', `dashboard/events/${serialNumber}`);
  URL_STATS = () => this.url('socle', 'dashboard/stats');

  // User
  URL_USER_SETTINGS = () => this.url('socle', 'dashboard/user/settings');

  insertToken = (url) => ![this.URL_LOGIN, this.URL_REFRESH_TOKEN].includes(url);
}
