import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { UrlService } from '../service/url.service';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  constructor(private router: Router, private authService: AuthService, private urlService: UrlService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.injectApiKey(req)

    // We don't add the token for the login and the refresh token
    if (this.urlService.insertToken(req.urlWithParams)) {
      req = this.getCloneRequestWithToken(req);
    }
    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          return this.handle401Error(req, next, err);
        }
        if (err.status === 403) {
          this.router.navigate(['/products']);
          return throwError(err);
        }
        return throwError(err);
      })
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler, err: any): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.authService.refreshToken().pipe(
        switchMap(data => {
          console.log('Token refreshed, trying to send again the last request...', data);

          this.isRefreshing = false;

          // Update the request with in the header request
          req = this.getCloneRequestWithToken(req);

          return next.handle(req);
        })
      );
    } else {
      this.isRefreshing = false;

      this.authService.clear();
      this.router.navigateByUrl('auth/login');
      return throwError(err);
    }
  }

  private getCloneRequestWithToken(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken() !== null ? this.authService.getToken() : ''}`
      }
    });
  }

  private injectApiKey(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        'x-api-key': environment.xapikey
      }
    })
  }
}
